/*
*
* Boxes
*/

// 
// Table of Contents:
//
// Box Minimal
// Box Counter

//
// Box Minimal
//
.box-minimal {
	text-align: center;

	.box-minimal-icon {
		font-size: 50px;
		line-height: 50px;
		color: $primary;
	}
}

.box-minimal-divider {
	width: 36px;
	height: 4px;
	margin-left: auto;
	margin-right: auto;
	background: $primary;
}

.box-minimal-title {
	font-family: $font-family-sec;
}

.box-minimal-text {
	width: 100%;
	max-width: 335px;
	margin-left: auto;
	margin-right: auto;
	letter-spacing: 0.02em;
}


* + .box-minimal {
	margin-top: 30px;
}

* + .box-minimal-title {
	margin-top: 10px;
}

* + .box-minimal-divider {
	margin-top: 20px;
}

* + .box-minimal-text {
	margin-top: 15px;
}

// Context styling
%context-dark {
	.box-minimal {
		p {
			color: $body-color;
		}
	}
}

//
// Box Counter
//
.box-counter {
	position: relative;
	text-align: center;
	color: $gray-700;
	z-index: 1;
	
}

.box-counter-title {
	display: inline-block;
	font-family: $font-family-sans-serif-1;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: .05em;
	font-weight: 700;
	text-transform: uppercase;
	color: $gray-800;
}

.box-counter-main {
	font-family: $font-family-sec;
	font-size: 45px;
	font-weight: 700;
	line-height: 1.2;

	> * {
		display: inline;
		font: inherit;
	}

	.small {
		font-size: 28px;
	}

	.small_top {
		position: relative;
		top: .2em;
		vertical-align: top;
	}
}

.box-counter-divider {
	font-size: 0;
	line-height: 0;
	&::after {
		content: '';
		display: inline-block;
		width: 80px;
		height: 2px;
		background: $primary;
	}
}

* + .box-counter-title {
	margin-top: 10px;
}

* + .box-counter-main {
	margin-top: 20px;
}

* + .box-counter-divider {
	margin-top: 10px;
}


@include media-breakpoint-up(xxl) {
	.counter-list-border {
		.col-md-3 {
			border-right: 1px solid $gray-4;
			&:last-child {
				border-right: none;
			}
		}
	}
	.box-counter {
		&:before,
		&:after {
			display: none;
		}
	}
}

@include media-breakpoint-up(xl) {
	.box-counter-main {
		font-size: 60px;

		.small {
			font-size: 36px;
		}
	}

	* + .box-counter-main {
		margin-top: 30px;
	}
	* + .box-counter-divider {
		margin-top: 15px;
	}
}

// Context styling
.bg-primary {
	.box-counter {
		color: $white-invariable;
	}

	.box-counter-divider {
		&::after {
			background: $white-invariable;
		}
	}
}





// Box icon modern
.box-icon-modern {
	position: relative;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	padding: 20px;
	transition: all .3s;
	
	> * {
		position: relative;
	}
	.icon-modern {
		position: relative;
		display: inline-block;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background: $gray-100;
		svg {
			> * {
				color: $primary;
				fill: $primary;
			}
			
		}
	}
	.divider {
		&:after {
			background: $gray-2;
		}
	}
	
	&:before {
		position: absolute;
		content: '';
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		background: $white;
		box-shadow: 0 0 22px rgba($black, .1);
		transition: all .3s;
		transform: translate(-50%, -50%);
		
		opacity: 0;
		
	}
	* + .divider {
		margin-top: 15px;
	}
	
	&:hover {
		z-index: 1;
		&:before {
			width: 104%;
			height: 104%;
			opacity: 1;
		}
	}
	
	@include media-breakpoint-up(xl) {
		padding: 40px 32px;
	}
	&.modern-variant-2 {
		.divider {
			display: none;
		}
	}
}
.box-icon-modern-title {
	font-family: $font-family-sec;
}
* + .box-icon-modern-title {
	margin-top: 30px;
}
.icon-modern-list {
	@include media-breakpoint-up(lg) {
		> * {
			.box-icon-modern {
				border-right: 1px solid $gray-2;
				border-bottom: 1px solid $gray-2;
			}
			
			&:last-child, &:nth-child(3) {
				.box-icon-modern {
					border-right: 1px solid transparent;
				}
				
			}
			&:nth-child(n + 4) {
				.box-icon-modern {
					border-bottom: 1px solid transparent;
				}
				
			}
		}
	}
}

// box icon classic
.box-icon-classic{
	position: relative;
	padding: 40px 20px;
	.icon-classic {
		position: relative;
		display: inline-block;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background: $gray-10;
		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			> * {
				color: $primary;
				fill: $primary;
			}
		}
	}
	.icon-classic-title {
		font-family: $font-family-sec;
	}
	* + .icon-classic-title {
		margin-top: 15px;
	}
	* + .icon-classic-body {
		margin-top: 25px;
	}
	@include media-breakpoint-up(lg) {
		display: flex;
		align-items: center;
		padding: 50px 25px;
		> * {
			padding: 0 20px;
		}
		.icon-classic-body {
			text-align: left;
		}
		
		* + .icon-classic-body {
			margin-top: 0;
		}
	}
}

// box icon classic vertical
.box-icon-classic-vertical {
	text-align: center;

	.icon-classic-body {
		max-width: 360px;
		margin-left: auto;
		margin-right: auto;
	}
	
	@include media-breakpoint-up(lg) {
		display: block;
		padding: 50px 4px;
		.icon-classic-body {
			text-align: center;
		}
		* + .icon-classic-body {
			margin-top: 20px;
		}
	}
}


.box-icon-classic-list {
	.box-icon-classic {
		&:before {
			position: absolute;
			content: '';
			left: 50%;
			bottom: 0;
			height: 1px;
			width: 85%;
			background: $gray-10;
			transform: translateX(-50%);
		}
		
	}
	@include media-breakpoint-up(md) {
		.box-icon-classic {
			&:after {
				position: absolute;
				content: '';
				top: 50%;
				right: 0;
				width: 1px;
				height: 75%;
				background: $gray-10;
				transform: translateY(-50%);
			}
			
		}
		> *:nth-child(n + 3) {
			.box-icon-classic {
				&:before {
					display: none;
				}
			}
		}
		> *:nth-child(2n + 2) {
			.box-icon-classic {
				&:after {
					display: none;
				}
			}
		}
	}
}

.box-icon-classic-bordered {
	> * {
		border-bottom: 1px solid $gray-10;
		&:last-child {
			border-bottom: none;
		}
	}
	@include media-breakpoint-up(md) {
		> * {
			border-right: 1px solid $gray-10;
		}
		> *:nth-child(n + 3) {
			border-bottom: none;
		}
		> *:nth-child(2n + 2) {
			border-right:  none;
		}
	}
}


// box icon minimal
.box-icon-minimal {
	max-width: 300px;
	text-align: left;
	
}
.box-icon-minimal-header {
	display: flex;
	align-items: center;
	svg {
		> * {
			fill: $primary;
		}
	}
}

.box-icon-minimal-title {
	font-family: $font-family-sec;
	font-size: 21px;
	font-weight: 700;
	letter-spacing: 0.06em;
	color: $gray-800;
	text-transform: uppercase;
	a {
		color: $gray-800;
		&:hover {
			color: $primary;
		}
	}
}

* + .box-icon-minimal-text {
	margin-top: 22px;
}
* + .box-icon-minimal-title {
	margin-left: 20px;
	margin-top: 0;
}

//
// Box images
//
.box-images {
	position: relative;
	max-width: 570px;
	&.box-images-modern {
		display: flex;
		align-items: center;
	}
	
}
.box-images-item {
	display: inline-block;
	border: 10px solid $white;
	box-shadow: 0 0 35px rgba($black, .13);
	width: 50%;
	@include media-breakpoint-up(md) {
		width: inherit;
	}
	&.box-images-without-border {
		border: none;
		margin-right: 20%;
	}
}

.box-images-variant-3 {
	.box-images-item {
		width: inherit;
	}
}

.box-images-1 {
	position: absolute;
	left: 0;
	top: 100px;
	z-index: 4;
}
.box-images-2 {
	position: relative;
	margin-left: 32%;
	z-index: 3;
}
.box-images-3 {
	position: absolute;
	right: 0;
	bottom: 100px;
	z-index: 2;
}
.box-images-4 {
	position: relative;
	margin-top: -5%;
	margin-left: 17%;
	z-index: 1;
}

.box-images-modern {	
	.box-images-item:first-child {
		z-index: 2;
		margin-right: -10%;
	}
}

.box-images-variant-3 {
	.box-images-item:first-child {
		position: relative;
		margin-left: 20%;
		z-index: 2;
	}
	.box-images-item.box-images-without-border {
		position: relative;
		margin-top: -46%;
		z-index: 1;
	}
}

// box image classic
.box-images-classic {
	.box-image-item {
		display: inline-block;
		box-shadow: 0 0 35px rgba($black, .13);
	}
	* + .box-image-item {
		margin-top: 30px;
	}
}
//
// Box video
//
.box-video {
	position: relative;
	.button-play{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 85px;
		height: 85px;
		border-radius: 50%;
		background: rgba($white, .5);
		transform: translate(-50%, -50%);
		&:before {
			position: absolute;
			font-family: "FontAwesome";
			content: '\f04b';
			top: 50%;
			font-size: 20px;
			line-height: 20px;
			left: 54%;
			color: $white;
			text-shadow: 0 0 4px rgba($black, .04);
			transform: translate(-50%, -50%);
			transition: all 0.3s ease-in-out;
		}
		&:hover,  &:focus {
			background: rgba($white, .8);
			&:before {
				color: $primary;
			}
		}
	}
}


// video button
.box-video-button {
	text-align: center;
	.button-play {
		position: relative;
		display: inline-block;
		width: 90px;
		height: 90px;
		border-radius: 50%;
		border: 2px solid rgba($white, .6);
		background: rgba($white, .3);
		transition: all .3s;
		z-index: 1;
		
		&:before {
			position: absolute;
			content: '';
			top: -12px;
			left: -12px;
			right: -12px;
			bottom: -12px;
			background: rgba($white, .21);
			border-radius: 50%;
			transition: all .3s;
		}
		.icon {
			position: absolute;
			top: 50%;
			left: 55%;
			font-size: 30px;
			color: $white;
			transform: translate(-50%, -50%);
			transition: all .3s;
		}
		&:hover {
			background: rgba($white, .6);
			.icon {
				color: $primary;
			}
			&:before {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}
}


//
// Gallery item
//
.gallery-item{
	position: relative;
	display: block;
	background: $black;
	overflow: hidden;
	img {
		position: relative;
		transition: all .5s;
		z-index: 1;
		will-change: transform;
		width: 100%;
	}
	&:before {
		position: absolute;
		content: '';
		top: 20px;
		left: 20px;
		right: 20px;
		bottom: 20px;
		border: 2px solid $white;
		transition: all .5s;
		opacity: 0;
		z-index: 2;
	}
	.gallery-item-title {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 24px;
		font-weight: 700;
		color: $white;
		transform: translate(-50%, -50%);
		transition: all .3s;
		opacity: 0;
		z-index: 2;
	}
	.gallery-item-button {
		position: absolute;
		display: inline-block;
		overflow: hidden;
		right: 20px;
		bottom: 20px;
		width: 66px;
		height: 66px;
		opacity: 0;
		transition: all .5s;
		z-index: 2;
		
		&:before {
			position: absolute;
			content: '+';
			right: 11px;
			bottom: 7px;
			color: $primary;
			font-size: 28px;
			line-height: 28px;
			z-index: 1;
			
		}
		&:after {
			position: absolute;
			content: '';
			top: 0;
			left: 33px;
			right: -33px;
			bottom: 0;
			background: $white;
			transform: skewX(-45deg);
		}
	}
	.mobile &, .tablet & {
		.gallery-item-title, .gallery-item-button, &:before  {
				opacity: 1;
			}
			img {
				opacity: .7;
			}
	}
	&:hover {
		img {
			opacity: .7;
			transform: scale(1.05);
		}
		&:before, .gallery-item-button, .gallery-item-title {
			opacity: 1;
		}
	}
}
.cobbles-gallery {
	.gallery-item {
		@include media-breakpoint-up(xl) {
			img {
				max-width: initial;
				width: 100%;
			}
		}
		
	}
}
@include media-breakpoint-down(sm) {
	.gallery-item {
		img {
			width: 100%;
		}
	}
}
//
// Team boxes
//

.team-minimal {
	text-align: center;
	.team-title {
		font-family: $font-family-sec;
		
	}

	.team-minimal-caption {
		padding: 20px 5px;
		background: $white;
		white-space: nowrap;
		* + p {
			margin-top: 10px;
		}
	}
	
	
	&.team-minimal-with-shadow {
		figure {
			display: inline-block;
			box-shadow: 0 0 22px rgba($black, .1);
		}
	}
	&.team-minimal-type-2 {
		max-width: 320px;
		margin-left: auto;
		margin-right: auto;
		box-shadow: 0 0 22px rgba($black, .1);
	}
}
.carousel-centered {
	.slick-slide {
		.team-minimal {
			img {
				display: inline-block;
			}
		}
		@include media-breakpoint-up(md) {
			&.slick-active {
				width: 170px !important;
				&.slick-current {
					width: 280px !important;
				}
			} 
			
		}
		@include media-breakpoint-up(xl) {
			&.slick-active {
				width: 170px !important;
				&.slick-current {
					width: 370px !important;
				}
			}

		}
		
		@include media-breakpoint-up(md) {
			.team-minimal-caption {
				margin-top: 0;
				height: 1px;
				opacity: 0;
			}
			&.slick-current {
				.team-minimal-caption {
					margin-top: 25px;
					height: auto;
					opacity: 1;
				}
			}
		}
	}
}

// team corporate
.team-corporate {
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
	padding: 25px;
	border: 3px solid $gray-11;
	transition: all .3s;
	&:hover {
		box-shadow: 0 0 22px rgba($black, .1);
		
	}
}
.team-corporate-title {
	font-family: $font-family-sec;
}
.team-corporate-link {
	a{
		color: $gray-800;
		&:hover {
			color: $primary;
		}
	}
}
.team-corporate-caption {
	> * + * {
		margin-top: 15px;
	}
}
* + .team-corporate-caption {
	margin-top: 30px;
}

// box team info
.box-team-info {
	.box-team-info-header {
		* + p {
			margin-top: 7px;
		}
		* + .divider-lg {
			margin-top: 15px;
		}
	}
	> * + * {
		margin-top: 30px;
	}
	@include media-breakpoint-up(lg) {
		> * + * {
			margin-top: 40px;
		}
	}
}

.box-team-info-carousel {
	max-width: 590px;
}


// box-team-creative
.box-team-creative {
	position: relative;
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 0 22px rgba($black, .1);
	
	.social-list {
		visibility: hidden;
		opacity: 0;
		margin-bottom: -35px;
		transition: all .7s;
	}

	.box-team-creative-caption {
		position: absolute;
		padding: 25px 15px 25px;
		left: 0;
		bottom: 0;
		right: 0;
		background: $white;
		box-shadow: inset 0 -3px 0  0 $primary;
		transition: all .7s;
	}
	.box-team-creative-title {
		font-family: $font-family-sec;
		font-weight: 700;
		letter-spacing: 0.04em;
	}
	.icon {
		font-size: 21px;
	}
	
	* + .social-list {
		margin-top: 15px;
	}
	&:hover {
		.social-list {
			visibility: visible;
			opacity: 1;
			margin-bottom: 0;
			
		}
		.box-team-creative-caption {
			box-shadow: inset 0 -5px 0  0 $primary;
		}
	}
}



//
// pricing box
//
.pricing-classic-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
	padding: 25px 20px 25px 0;
	border-bottom: 1px solid $gray-10;
}
.pricing-classic-body {
	h4 {
		font-weight: 400;
	}
	> * + * {
		margin-top: 9px;
	}
}
.price-value {
	h3{
		font-weight: 400;
		color: $primary;
	}
	
}

.pricing-list-classic {
	@include media-breakpoint-up(lg) {
		column-count: 2;
		column-gap: 30px;
		.pricing-classic-item:nth-child(4n + 4) {
			border-bottom: none;
		}
	}
}

//pricing box modern
.pricing-box-modern {
	border: 3px solid $primary;
	.pricing-box-inner {
		padding: 35px 15px;
		.pricing-box-inner-list {
			h4 {
				font-family: $font-family-sec;
			}
			p {
				font-size: 14px;
				font-family: $font-family-sans-serif-1;
				letter-spacing: 0.06em;
				text-transform: uppercase;
				font-weight: 500;
				opacity: .6;
			}
			h4 + p {
				margin-top: 7px;
			}
		}
		* + .pricing-box-inner-list {
			margin-top: 20px;
		}
		
		@include media-breakpoint-up(md) {
			padding: 40px 60px;
		}
		@include media-breakpoint-up(xl) {
			&.box-left {
				padding-right: 75px;
			}
			&.box-right {
				padding-left: 120px;
			}
		}
		
	}
	.img-wrap {
		overflow: hidden;
		img {
			position: absolute;
			max-width: none;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}
		@include  media-breakpoint-up(xl) {
			overflow: visible;
			img {
				box-shadow: 0 0 22px rgba($black, .1);
			}
		}
	}
}

//
// Box service modern
//
.box-service-modern {
	display: flex;
	flex-direction: column;
	
	
	
	.box-icon-classic {
		max-width: 390px;
		margin-left: auto;
		margin-right: auto;
		border: 1px solid $gray-200;
		
	}
	.box-icon-classic .icon-classic {
		background: $gray-100;
	}
	.box-icon-classic-vertical .icon-classic-body {
		max-width: inherit;
	}

	.box-service-modern-img {
		position: relative;
		overflow: hidden;
		&:before {
			position: absolute;
			content: '';
			top: -4px;
			left: 50%;
			width: 20px;
			height: 20px;
			background: $white;
			transform: rotate(45deg) translateX(-50%);
		}
		&:after {
			position: absolute;
			content: '';
			left: 50%;
			top: -4px;
			width: 27px;
			height: 27px;
			border: 1px solid $white;
			transform: rotate(45deg) translateX(-53%);
		}
	}
	
	@include media-breakpoint-only(md) {
		flex-direction: row;
		&.box-service-modern-reverse {
			flex-direction: row-reverse;
		}
		.box-icon-classic {
			padding: 36px 15px;
			width: 345px;
		}
		.box-service-modern-img {
			width: 345px;

			&:before, &:after {
				display: none;
			}
			
			img {
				max-width: inherit;
			}
		} 
	}
	@include media-breakpoint-up(lg) {
		flex-direction: column;
		.box-service-modern-img {
			&:before, &:after {
				display: block;
			}
			img {
				max-width: inherit;
			}
		}
		
		&.box-service-modern-reverse {
			flex-direction: column-reverse;
			.box-icon-classic {
				border-top: 1px solid transparent;
				border-bottom: 1px solid $gray-200;
			}
			.box-service-modern-img {
				&:before, &:after {
					top: 100%;
				}
			}
		}
		.box-icon-classic {
			min-height: 310px;
			border-bottom: 1px solid transparent;
			> * {
				padding: 0 12px;
			}
		}
	}
	
}


// box contact info
.box-contact-info-with-icon {
	position: relative;
	padding-left: 38px;
	.icon {
		position: absolute;
		font-size: 28px;
		top: 0;
		left: 0;
	}
	* + .list-xs {
		margin-top: 12px;
	}
}

.box-info-with-shadow {
	padding: 25px 30px;
	text-align: left;
	background: $white;
	border-radius: 5px;
	box-shadow: 0 0 22px rgba($black, .1);
	.list{
		li {
			position: relative;
			padding-left: 40px;
			.icon {
				position: absolute;
				font-size: 24px;
				left: 0;
			}
		}
	}
	* + .list {
		margin-top: 20px;
	}
}


//box thumbnail classic
.box-thumbnail-classic {
	display: inline-block;

	figure {
		border-radius: 6px;
		box-shadow: 0 8px 18px 0 rgba($gray-800, 0.25);
	}
	.caption {
		margin-top: 25px;
		font-family: $font-family-sec;
		color: $gray-800;
		font-weight: 500;
		> * {
			transition: all .3s;
			&:hover {
				color: $primary;
			}
		}
	}
}

// box advantages
.box-advantages-container {
	counter-reset: div;
}
.box-advantages {
	padding: 30px 20px;
	border-radius: 4px;
	text-align: left;
	transition: .33s ease-in;
	box-shadow: 0 0 18px rgba($gray-800, .11);

	&:hover{
		box-shadow: 0 0 18px rgba($gray-800, .22);
		.box-advantages-divider {
			width: 105px;
			background: $primary;
		}
	}

	.label {
		padding: 5px 8px;
		border-radius: 4px;

		font: 600 12px/1 $font-family-sec;
		text-transform: uppercase;
		letter-spacing: .05em;

		&-popular {
			color: $white;
			background-color: $danger-color;
		}
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		padding: 40px 20px 40px 30px;
	}
}

.box-advantages-icon {
	font-size: 50px;
	color: $primary;
}

.box-advantages-title {
	font-weight: 400;
	&:before {
		content: counter(div, decimal-leading-zero) '.';
		counter-increment: div;
		display: inline-block;
		opacity: .22;
		font-weight: 500;
		margin-right: 8px;
	}
}

.box-advantages-divider {
	height: 3px;
	width: 60px;
	background-color: rgba($gray-800, .18);
	transition: .33s ease-in;
}
.box-advantages-body {
	font-size: 15px;
	line-height: 22px;
	opacity: .8;
}

* + .box-advantages-title {
	margin-top: 15px;
}
* + .box-advantages-divider {
	margin-top: 25px;
}
* + .box-advantages-body {
	margin-top: 25px;
}


// box typography
.box-typography {
	font-size: 64px;
	font-weight: 900;
	font-family: $font-family-sec;

	&-inner {
		display: block;
		letter-spacing: .1em;

		&:first-of-type {

		}
		&:last-of-type {
			margin-top: 45px;
			line-height: .85;
			span {
				display: block;
			}
		}
	}
	@include media-breakpoint-up(md) {
		font-size: 108px;
	}
}

* + .box-typography {
	margin-top: 50px;
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	* + .box-typography {
		margin-top: 100px;
	}
}

.box-typography + * {
	margin-top: 45px;
}

