/*
*
* Lists
*/

// Vertical list
.list {
	> li + li {
		margin-top: 10px;
	}
}

.list-xs > li + li {
	margin-top: 5px;
}

.list-sm > li + li {
	margin-top: 10px;
}

.list-md > li + li {
	margin-top: 18px;
}

.list-lg > li + li {
	margin-top: 25px;
}
.list-xl > li + li {
	margin-top: 30px;
}
@include media-breakpoint-up(md) {
	.list-xl > li + li {
		margin-top: 70px;
	}
}

//
// List inline
//
.list-inline {
	> li {
		display: inline-block;
	}
}

html {
	.list-inline-md {
		@include spacing(20px, 8px);

		@include media-breakpoint-up(lg) {
			@include spacingX(30px);
		}
	}
}

//
// List terms
//
.list-terms {
	dt + dd {
		margin-top: 10px;
	}
	dd + dt {
		margin-top: 25px;
	}
}

* + .list-terms {
	margin-top: 25px;
}

//
// Index list
//
.index-list {
	counter-reset: li;

	> li {
		.list-index-counter {
			&:before {
				content: counter(li, decimal-leading-zero);
				counter-increment: li;
			}
		}
	}
}

//
// Marked list
//
.list-marked {
	padding-left: 25px;
	text-align: left;

	> li {
		text-indent: -25px;
		padding-left: 25px;
		&::before {
			position: relative;
			display: inline-block;
			left: 25px;
			top: -1px;
			min-width: 25px;
			content: '\f105';
			font: 400 14px/24px 'FontAwesome';
			color: $gray-1;
		}
	}

	> li + li {
		margin-top: 4px;
	}
	&.list-marked-primary {
		> li {
			&:before {
				color: $primary;
			}
		}
	}
}

* + .list-marked {
	margin-top: 15px;
}

p + .list-marked {
	margin-top: 10px;
}

//
// Ordered List
//
.list-ordered {
	counter-reset: li;
	padding-left: 25px;
	text-align: left;

	> li {
		position: relative;
		padding-left: 25px;

		&:before {
			content: counter(li, decimal) '.';
			counter-increment: li;
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 15px;
			color: $gray-1;
		}
	}

	> li + li {
		margin-top: 10px;
	}
}

* + .list-ordered {
	margin-top: 15px;
}


// social list
.social-list {
	@include spacingX(14px);
}
* + .social-list {
	margin-top: 40px;
}

// contact list
.contact-list {
	> li{
		padding: 24px 0;
		border-bottom:  1px solid $gray-10;
		&:last-child{
			border-bottom: none;
		}
	}
	.contact-list-title {
		font-family: $font-family-sec;
		font-size: 21px;
		font-weight: 700;
		color: $gray-800;
	}
	.contact-list-content {
		position: relative;
		padding-left: 30px;
		.icon {
			position: absolute;
			top: 1px;
			left: 0;
			font-size: 24px;
		}
		a {
			color: $gray-500;
			&:hover {
				color: $primary;
			}
		}
	}
	* + .contact-list-content {
		margin-top: 15px;
	}
}