/*
*
* RD Google Map
*/

.gm-style-pbt {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: $gray-900;
	color: $white;
	padding: 5px 14px;
	font-size: 16px;
	border-radius: 3px;
	box-shadow: $shadow-area-2;
	width: 100%;
	text-align: center;
	max-width: 300px;
}

.rd-google-map__model {
	color: $black;
	height: $map-height;

	img {
		max-width: none !important;
	}

	@include media-breakpoint-up(sm) {
		height: $map-xs-height;
	}

	@include media-breakpoint-up(lg) {
		height: $map-md-height;
	}
}

.map_locations {
	display: none;
}
