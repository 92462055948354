/*
*
* Sections
*/

//
// Section Spacing
// 
.section-xxs {
	padding:12px 0;
}
.section-xs {
	padding: 25px 0;
}
.section-xs-type-1 {
	padding: 30px 0;
}


.section-sm,
.section-md,
.section-lg,
.section-xl {
	padding: 50px 0;
}

// Section collapse
.section-collapse + .section-collapse {
	padding-top: 0;
}

.section-collapse:last-child {
	padding-bottom: 0;
}

// Section with bottom zero padding
html [class*='section-'].section-bottom-0 {
	padding-bottom: 0;
}

@include media-breakpoint-down(md) {
	section.section-sm,
	section.section-md,
	section.section-lg,
	section.section-xl,
	section.section-xxl {
		&:first-of-type {
			padding-top: 40px;
		}
	}
}

@include media-breakpoint-up(md) {
	.section-sm {
		padding: 60px 0;
	}

	.section-md {
		padding: 70px 0;
	}

	.section-lg {
		padding: 85px 0;
	}
}

@include media-breakpoint-up(lg) {
	.section-md {
		padding: 80px 0 90px;
	}

	.section-lg {
		padding: 100px 0;
	}
	.section-xl {
		padding: 120px 0;
	}
}

@include media-breakpoint-up(xl) {
	.section-lg {
		padding: 115px 0;
	}
	.section-xl {
		padding: 140px 0;
	}
}

//
// Custom sections
//

// Section single
.section-single {
	position: relative;
	display: flex;
	text-align: center;
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: $white;
		opacity: 0;
		@include media-breakpoint-down(md) {
			opacity: 0.85;
		}
	}
	> * {
		position: relative;
	}

	p {
		margin-left: auto;
		margin-right: auto;
	}

	* + .rights {
		margin-top: 35px;
	}
	* + .countdown-wrap {
		margin-top: 35px;
	}
	.countdown-wrap + * {
		margin-top: 35px;
	}

	@include media-breakpoint-up(lg) {
		* + .rights {
			margin-top: 60px;
		}
		.countdown-wrap + * {
			margin-top: 35px;
		}
	}

	.rd-mailform-wrap {
		max-width: 570px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	* + .rd-mailform-wrap {
		margin-top: 30px;
	}
}

.section-single-header {
	padding: calc(1em + 3vh) 0 calc(1em + 2vh);
}

.section-single-main {
	padding: calc(1em + 4vh) 0;
}

.section-single-footer {
	padding: calc(1em + 2vh) 0 calc(1em + 3vh);
}

.section-single-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;

	> * {
		width: 100%;
	}
}

@supports (display: grid) {
	.section-single-inner {
		display: grid;
		justify-items: center;
		align-content: space-between;
		grid-template-columns: 1fr;
	}
}


// Page Title
.section-page-title {
	padding: 80px 0;
}

//
// section filters
//
.section-filter-white {
	position: relative;
	> *{
		position: relative;
	}
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;	
		bottom: 0;
		background: rgba($white, .2);
	}
}

.section-filter-dark {
	position: relative;
	> *{
		position: relative;
	}
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, .2);
	}
}


/**
* Custom sections
*/
.section-two-column {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	> * {
		flex-basis: 100%;
	}
	@include media-breakpoint-up(lg) {
		flex-direction: row;
		> * {
			flex-basis: 50%;
		}
		.section-map {
			.rd-google-map__model {
				height: 100%;
			}
		}
	}
	
}
.section-two-column-content {
	padding: 0 15px;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	@include media-breakpoint-up(lg) {
		padding-left: 40px;
		margin-left: 0;
	}
	@include media-breakpoint-up(xl) {
		padding-left: 80px;
	}
}


//
// section transform bottom
//
.section-transform-bottom {
	position: relative;
	z-index: 1;
	@include media-breakpoint-up(xl) {
		margin-bottom: -130px;
	}
}
