/**
*
* RD Navbar Classic
* =====================================================
*/
.rd-navbar-classic {
	// Static
	&.rd-navbar-static {
		border-bottom: 1px solid transparent;

		.rd-navbar-aside-outer,
		.rd-navbar-main-outer {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}

		.rd-navbar-aside-outer {
			background: $primary;
			
		}
		
		.rd-navbar-aside,
		.rd-navbar-main {
			max-width: $rd-navbar-static-width;
			margin-left: auto;
			margin-right: auto;
		}

		.rd-navbar-aside {
			padding: 12px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: $white;

			.header-info {
				font-size: 14px;
				.list-inline {
					@include spacingX(80px);
				}
				.unit-body {
					color: rgba($white, .75);
				}
				a {
					font-size: 14px;
					color: rgba($white, .75);
					&:hover {
						color: $white;
					}
				}
			}
			
			.social-block {
				.list-inline {
					@include spacingX(22px);
				}
				a {
					font-size: 15px;
					color: rgba($white, .75);
					&:hover {
						color: $white;
					}
				}
			}
		}
		
		
		
		
		.rd-navbar-main {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 0;
		}

		// RD Navbar Brand
		.rd-navbar-brand {
			img {
				width: auto;
				height: auto;
				max-width: 200px;
				max-height: 200px;
			}
		}

		.rd-navbar-main-element {
			display: flex;
			align-items: center;
		}

		&.rd-navbar--is-stuck,
		&.rd-navbar--is-clone {
			border-bottom-color: nth($rd-navbar-border, 3);
			.rd-navbar-aside-outer {
				display: none;
			}

			.rd-navbar-main {
				padding: 6px 0;
			}

			.rd-navbar-nav-item {
				> .rd-navbar-submenu {
					margin-top: 17px;
				}
			}
		}

		.rd-nav-item + .rd-nav-item {
			margin-left: 30px;
			@include media-breakpoint-up(xl) {
				margin-left: 46px;
			}
			
		}
		
	}

	// Fixed 
	&.rd-navbar-fixed {

	}
}
