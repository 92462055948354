/*
*
* Links
*/

// Standard link style
a {
	transition: $transition-base;

	&,
	&:focus,
	&:active,
	&:hover {
		text-decoration: $link-decoration;
	}

	@include link($link-color, $link-hover-color);

	&[href*='tel'],
	&[href*='mailto'] {
		white-space: nowrap;
	}
}

.link-hover {
	color: $link-hover-color;
}

.link-press {
	color: $link-press-color;
}

.privacy-link {
	display: inline-block;
}

* + .privacy-link {
	margin-top: 35px;
}
