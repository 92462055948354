/*
*
* Grid modules
*/

//
// Grid demonstration
//
.grid-demonstration {
	$grid-heading: unquote("h5, .heading-5");
	padding: 12px 8px;
	letter-spacing: 0;
	text-align: left;

	#{$grid-heading} {
		font-weight: 700;
	}

	@include media-breakpoint-down(lg) {
		#{$grid-heading} {
			font-size: 14px;
		}
	}

	@include media-breakpoint-down(xl) {
		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.container-fluid.grid-demonstration {
	@include media-breakpoint-down(md) {
		.row {
			margin-left: 0;
			margin-right: 0;
		}
	}

	[class^='col'] {
		padding: 5px;
		@include media-breakpoint-down(md) {
			p {
				font-size: 14px;
			}

			* + p {
				margin-top: 10px;
			}
		}

		@include media-breakpoint-up(md) {
			padding: 15px 10px;
		}

		@include media-breakpoint-up(lg) {
			padding: 25px 15px;
		}

		@include media-breakpoint-up(xl) {
			padding: 30px 15px 30px 30px;
		}

		@include media-breakpoint-up(xxl) {
			padding: 50px 100px;

			* + p {
				margin-top: 24px;
			}
		}
	}

	.row {
		@include media-breakpoint-up(xxl) {
			margin-left: -70px;
			margin-right: -70px;
		}
	}
}

//
// Grid system bordered
//
.grid-system-bordered {
	[class*='col'] {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.row {
		border-bottom: 1px solid $gray-200;
		&:last-child {
			border-bottom:none;
		}
	}

	@include media-breakpoint-up(md) {
		[class*='col'] {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	@include media-breakpoint-up(xl) {
		[class*='col'] {
			padding-top: 45px;
			padding-bottom: 45px;
		}
	}
}

//
// Grid system outline
//
.grid-system-outline {
	.row {
		border: 1px solid $gray-200;

		&:not(:last-child) {
			border-bottom-width: 0;
		}
	}

	[class*='col']:not(:last-child) {
		border-right: 1px solid $gray-200;
		margin-right: -1px;
	}

	* + .row {
		margin-top: 45px;
	}
	.row + .row {
		margin-top: 0;
	}
}

// Custom Grid
//
// Change $enable-grid-classes-custom: true
// that would generate custom grid (example: 10 columns)

$enable-grid-classes-custom: false;

@mixin make-grid-columns-custom($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			@for $i from 1 through $columns {
				.col#{$infix}-#{$i} {
					@include make-col($i, $columns);
				}
			}
		}
	}
}

@if $enable-grid-classes-custom {
	.row-ten {
		@include make-grid-columns-custom(10);
	}

	.row-xl-ten {
		@include make-grid-columns-custom(10, 30px, (xl: map-get($grid-breakpoints, xl), xlg: map-get($grid-breakpoints, xlg), xxl: map-get($grid-breakpoints, xxl)));
	}
}
