/*
*
* Pagination custom
*/

.pagination {
	font-size: 0;
	line-height: 0;
	@include group(10px);
	
}

.page-item {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	font-weight: 700;
	&:first-child {
		.page-link{
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
	}
	&:last-child {
		.page-link{
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}
}

// Base styles
.page-link {
	display: inline-block;
	min-width: $pagination-padding-y*2 + $pagination-border-width*2 + $pagination-line-height;
	padding: $pagination-padding-y $pagination-padding-x;
	border: $pagination-border-width solid $pagination-border-color;
	font-size: $pagination-font-size;
	line-height: ($pagination-line-height / $pagination-font-size);
	background-color: $pagination-bg;
	color: $pagination-color;
	transition: $transition-base;
	border-radius: 5px;
	.page-item-control & {
		font-family: $font-family-sans-serif-1;
		font-size: 14px;
		line-height: 24px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.027em;
	
	}
}

@include media-breakpoint-down(xs) {
	.pagination > * {
		margin-left: 7px;
	}
	.page-link {
		display: inline-block;
		min-width: 40px;
		padding: 6px 6px;
		border: $pagination-border-width solid $pagination-border-color;
		font-size: 12px;
		line-height: ($pagination-line-height / $pagination-font-size);
		background-color: $pagination-bg;
		color: $pagination-color;
		transition: $transition-base;
		border-radius: 5px;
		.page-item-control & {
			font-family: $font-family-sans-serif-1;
			font-size: 12px;
			line-height: 24px;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0.027em;

		}
	}
}

// Hover & Focus & Active State
.page-link {
	&:hover,
	&:focus,
	&:active {
		color: $pagination-hover-color;
		background-color: $pagination-hover-bg;
		border-color: $pagination-hover-border-color;
	}
}

// Active State
.page-item.active > .page-link {
	&,
	&:hover,
	&:focus,
	&:active {
		color: $pagination-active-color;
		background-color: $pagination-active-bg;
		border-color: $pagination-active-border-color;
	}
}

// Disabled State
.page-item.disabled > .page-link {
	&,
	&:hover,
	&:focus,
	&:active {
		color: $pagination-disabled-color;
		background-color: $pagination-disabled-bg;
		border-color: $pagination-disabled-border-color;
	}
}

.page-item-control {
	.icon::before {
		font-family: 'FontAwesome';
		font-size: 11px;
		line-height: $pagination-line-height;
	}
}

// Prev control
.page-item-control:first-child {
	.icon::before {
		content: '\f053';
		margin-left: -1px;
	}
}

// Next control
.page-item-control:last-child {
	.icon::before {
		content: '\f054';
		margin-right: -1px;
	}
}
