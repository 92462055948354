/*
*
* Progress Bars
*/

//
// Linear Progress Bars
//

.progress-linear {
	position: relative;
	text-align: left;

	.progress-header {
		font-family: $font-family-sans-serif-1;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		font-weight: 600;
	}

	.progress-bar-linear-wrap {
		display: block;
		flex: 1;
		height: 10px;
		background: $gray-100;
		border-radius: 5px;
	}

	.progress-body {
		display: flex;
		align-items: flex-end;
	}

	* + .progress-body {
		margin-top: 12px;
	}
	
	.progress-bar-linear {
		width: 0;
		height: inherit;
		background: $primary;
		transition: .5s all ease-in-out;
		border-radius: 5px;
	}

	.progress-value {
		font-weight: 700;
		color: #b8b8b6;
		margin-left: 10px;
		line-height: 1;
		&::after {
			content: "%";
		}
	}
}

* + .progress-bar-linear-wrap {
	margin-top: 8px;
}

.progress-linear + .progress-linear {
	margin-top: 20px;
}

@include media-breakpoint-up(lg) {
	.progress-linear + .progress-linear {
		margin-top: 30px;
	}
}
