/*
*
* Owl Carousel
*/

.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	padding-top: 17px;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	transition: scale 100ms ease;
	&:before {
		content: '\f144';
	}
}

.owl-carousel .owl-video-play-icon:hover {
	transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {
	&.disabled {
		display: none !important;
	}
}

.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font: 400 46px/50px 'FontAwesome';
	color: $gray-300;
	cursor: pointer; 

	&:hover {
		color: $primary;
	}
}

.owl-prev {
	left: 0;

	&::before {
		content: '\f104';
	}
}

.owl-next {
	right: 0;

	&::before {
		content: '\f105';
	}
}

/*
 * Owl Pagination
 */

.owl-dots {
	text-align: center;
	margin-top: 20px;

	&.disabled {
		display: none !important;
	}
}

.owl-dot {
	position: relative;
	display: inline-block;
	width: 15px;
	height: 15px;
	border: 2px solid $gray-12;
	border-radius: 50%;
	margin-left: 5px;
	margin-right: 5px;
	text-align: center;
	outline: none;
	cursor: pointer;
	background-color: transparent;
	transition: .2s;

	&:only-child {
		display: none;
	}

	&:hover,
	&:focus {
		background-color: $primary;
		border-color: $primary;
	}

	&.active {
		background-color: $primary;
		border-color: $primary;
	}
}

.owl-carousel.owl-carousel-light-dots {
	.owl-dot {
		border-color: $white;

		&:hover,
		&:focus {
			background-color: $white;
		}

		&.active {
			background-color: $white;
		}
	}
}


// carousel modern
.carousel-modern {
	padding: 0 20px 20px 0;
	&:before {
		position: absolute;
		content: '';
		top: 20px;
		left: 45px;
		right: 0;
		bottom: 0;
		background: $gray-8;
	}
	.owl-dots {
		position: absolute;
		bottom: 40px;
		width: 100%;
		line-height: 0;
	}
	.owl-dot {
		width: 25px;
		height: 3px;
		margin-left: 3px;
		margin-right: 3px;
		border: none;
		background: rgba($black, .3);
		border-radius: 0;
		&.active, &:hover {
			background: $primary;
		}
	}
	&.carousel-modern-type-1 {
		&:before {
			border: 2px solid $gray-10;
			background: transparent;
		}
		.owl-dots {
			top: 50%;
			bottom: inherit;
			right: 40px;
			margin-top: 0;
			width: auto;
			transform: translateY(-50%);
		}
		.owl-dot{
			display: block;
			margin: 4px 0;
			width: 12px;
			height: 12px;
			border: 2px solid #a9a9a9;
			border-radius: 50%;
			background: transparent;
			&:hover, &.active {
				background: $primary;
				border-color: $primary;
			}
		}
	}
}

// carousel creative 
.carousel-creative {
	.owl-stage {
		display: flex;
		align-items: center;
	}
	.owl-item {
		float: none; 
	}
	.owl-dot {
		width: 25px;
		height: 3px;
		margin-left: 3px;
		margin-right: 3px;
		border: none;
		background: rgba($black, .3);
		border-radius: 0;
		&.active, &:hover {
			background: $primary;
		}
	}
}

// carousel corporate
.carousel-corporate {
	.owl-stage-outer {
		padding: 15px 0;
	}
	
	.owl-dot {
		width: 15px;
		height: 15px;
		border: 2px solid transparent;
		background: $gray-4;
		margin: 0 8px;
		&:hover , &.active {
			border-color: $primary;
			background: $white;
		}
	}
	@include media-breakpoint-up(lg) {
		.owl-dots {
			text-align: right;
		}
	}
}

// carousel arrow circle
.carousel-arrow-circle {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.owl-nav {
		display: none;
		> * {
			padding: 17px 16px;
			width: 56px;
			height: 56px;
			border-radius: 50%;
			color: #c9c9c9;
			background: rgba($white, .8);
			font: 400 24px /24px "Material Design Icons";
			transition: all .3s;
			&:hover {
				background: $white;
				color: $primary;
			}
		}
		.owl-prev {
			left: -10px;
			&:before {
				content: '\f14a';
			}
		}
		.owl-next {
			right: -10px;
			&:before {
				content: '\f14f';
			}
		}
		@include media-breakpoint-up(xl) {
			display: block;
		}
		@media (min-width: 1400px) {
			.owl-prev {
				left: -50px;
			}
			.owl-next {
				right: -50px;
			}
		}
	}
	.owl-dots{
		.owl-dot {
			width: 15px;
			height: 15px;
			border: 2px solid transparent;
			background: $gray-4;
			margin: 0 8px;
			&:hover , &.active {
				border-color: $primary;
				background: $white;
			}
		}
	
		@include media-breakpoint-up(xl) {
			display: none;
		}
	}
	
}

.carousel-arrow-circle-modern {
	.owl-nav {
		> * {
			padding: 17px 16px;
			width: 56px;
			height: 56px;
			border-radius: 50%;
			color: #c9c9c9;
			background: rgba($white, .3);
			font: 400 24px /24px "Material Design Icons";
			transition: all .3s;
			&:hover {
				background: $primary;
				color: $white;
			}
		}
		.owl-prev {
			left: 20px;
			&:before {
				content: '\f14a';
			}
		}
		.owl-next {
			right: 20px;
			&:before {
				content: '\f14f';
			}
		}
		
	}
}

.carousel-inset {
	.owl-stage {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}


.carousel-default {
	.owl-nav {
		display: none;
	}
	@include  media-breakpoint-up(lg) {
		.owl-dots {
			display: none;
		}
		.owl-nav {
			display: block;
		}
		.owl-next {
			right: -15px;
		}
		.owl-prev {
			left: -15px;
		}
		
	}
	
}


.carousel-img {
	position: relative;
	overflow: hidden;
	background: $black;
	&:hover {
		img {
			transform: scale(1.05);
			opacity: .9;
		}
	}
	img {
		max-width: inherit;
		width: 100%;
		transform-origin: center;
		will-change: transform;
		transition: all .5s;
	}
}
.owl-carousel.owl-outer-padding-none .owl-stage-outer {
	padding-top: 0;
} 

.owl-carousel-center {
	.owl-stage {
		//display: flex;
		//align-items: stretch;
		//justify-content: flex-start;
		height: 478px;
	}
	.owl-prev, .owl-next {
		width: 30px;
		height: 60px;
		transition: all .3s;
		color: rgba($white, .6);
		background: rgba($black, .8);
		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			font-family: 'FontAwesome';
			font-size: 24px;
			transform: translate(-50%,-50%);
			line-height: 1;
		}
		&:hover, &:focus {
			background: $black;
			color: $white;
		}
		@include  media-breakpoint-up(xxl) {
			background: transparent;
			color: rgba($gray-800, .3);
			&:before {
				color: inherit;
			}
			&:hover, &:focus {
				color: rgba($gray-800, 1);
				background: transparent;
				&:before {
					color: inherit;
				}
			}
		}
	}
	.owl-prev {
		&:before {
			content: '\f053';
			left: 48%;
		}
	}
	.owl-next {
		&:before {
			content: '\f054';
			left: 55%;
		}
	}
	
	//.owl-item {
	//	filter: grayscale(1);
	//	-webkit-filter: grayscale(1);
	//	float: none;
	//	cursor: move;
	//	display: flex;
	//	align-items: center;
	//	
	//	.team-minimal {
	//		overflow: hidden;
	//		transition: width .25s;
	//		.team-minimal-caption {
	//			display: none;
	//		}
	//	}
	//	&.active {
	//		.team-minimal {
	//			width: 170px;
	//		}
	//		width: 170px !important;
	//	}
	//	&.center.active {
	//		filter: none;
	//		width: 290px !important;
	//		.team-minimal {
	//			width: 290px;
	//			min-height: 397px;
	//			.team-minimal-caption {
	//				display: block;
	//			}
	//		}
	//		@include media-breakpoint-up(lg) {
	//			.team-minimal {
	//				width: 370px ;
	//			}
	//			width: 370px !important;
	//		}
	//	}
	//	@include media-breakpoint-up(lg) {
	//		&.active {
	//			width: 250px !important;
	//			min-height: 477px;
	//			.team-minimal {
	//				width: 250px ;
	//			}
	//			
	//		}
	//	}
	//	@include media-breakpoint-up(xl) {
	//		&.active {
	//			width: 170px !important;
	//			min-height: 474px;
	//			.team-minimal {
	//				width: 170px ;
	//				
	//			}
	//		}
	//	}
	//}
	.owl-item {
		filter: grayscale(1);
		-webkit-filter: grayscale(1);
		float: left;
		
		display: flex;
		align-items: center;
		

		.team-minimal {
			overflow: hidden;
			will-change: transform;
			transition: 0.7s transform ease;
			transform: scale(1) translateX(-50%);
			position: absolute;
			top: 0;
			left: 50%;
			width: 290px;
			transform-origin: 0;
			
			
			.team-minimal-caption {
				opacity: 0;
				transition: 0.7s all ease-in-out;
			}
			@include media-breakpoint-up(sm) {
				width: 370px;
				transform: scale(.5) translateX(-50%);
			}
		}
		
		&.center {
			filter: none;
			.team-minimal {
				
				
				transform: scale(1) translateX(-50%);
				position: absolute;
				top: 0;
				left: 50%;
				width: 290px;
				
				z-index: 1;
				.team-minimal-caption {
					opacity: 1;
				}
				@include media-breakpoint-up(sm) {
					width: 370px;
					transform: scale(1) translateX(-50%);
				}
			}
			@include media-breakpoint-up(lg) {
				.team-minimal {
					//width: 370px;
				}
				//width: 370px !important;
			}
		}
		@include media-breakpoint-up(lg) {
			&.active {
				//width: 250px !important;
				//min-height: 477px;
				//.team-minimal {
				//	width: 250px ;
				//}

			}
		}
		@include media-breakpoint-up(xl) {
			&.active {
				//width: 170px !important;
				//min-height: 474px;
				//.team-minimal {
				//	width: 170px ;
				//
				//}
			}
		}
	}
	@include  media-breakpoint-up(xxl) {
		.owl-prev {
			left: -50px;
		}
		.owl-next {
			right: -50px;
		}
	}
}

//.owl-carousel-center .owl-item.center {
//	filter: none;
//	width: 370px !important;
//}