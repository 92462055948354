/*
*
* Dividers
*/

hr {
	margin-top: 0;
	margin-bottom: 0;
	border-top: $hr-border-width solid $hr-border-color;
}

.divider {
	font-size: 0;
	line-height: 0;

	&::after {
		content: '';
		display: inline-block;
		width: 60px;
		height: 3px;
		background-color: $primary;
	}
	&-lg {
		
		&::after {
			content: '';
			display: inline-block;
			width: 90px;
			height: 3px;
			background-color: $primary;
		}
	}
}
h1 {
	.divider-lg {
		line-height: 3px;
	}
}